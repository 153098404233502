import { useState } from 'react'

import { SolidEmail, SolidSmsText, SolidLink } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import Switch from 'components/Switch'
import { useHistory, useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'

import ContentFormEmail from './ContentFormEmail'
import ContentFormText from './ContentFormText'
import { useFormContext } from '../../helpers/context'
import Line from '../Line'

export default function ContentForm(): JSX.Element {
  const {
    formData,
    stepLoading,
    viewOnly,
    createOrUpdate,
    handleFormDataChange,
  } = useFormContext()
  const history = useHistory()
  const { brandId, locationId } = useParams<TParamTypes>()
  const { selectedLocation } = useAppSelector(state => state.location)
  const [openSMSModal, setOpenSMSModal] = useState(false)
  const hasPhone =
    selectedLocation?.location_business_setting.marketing_phone_number

  const {
    name,
    via_email,
    via_sms,
    enable_cta,
    email_subject,
    email_image_url,
    email_headline,
    email_content,
    sms_content,
    type,
    campaign_offer,
  } = formData
  const disabled = type === 'one_off' && viewOnly
  const goBusinessSetting = async () => {
    await createOrUpdate()
    history.push(
      `/brand/${brandId}/location/${locationId}/business-setting/operation`,
    )
  }
  return (
    <div className='mt-8'>
      <div className='text-base text-ink font-bold pb-2'>Campaign Name</div>
      <div>
        <Field
          name='campaign name'
          type='text'
          placeholder='Write a campaign name'
          value={name}
          onChange={e => handleFormDataChange('name', e.target.value)}
          disabled={type === 'automated' || viewOnly}
        />
      </div>
      <Line className='mt-6 mb-6' />
      <div
        className={cn(
          'mb-6 rounded-lg',
          via_email ? 'border border-zinc p-4' : '',
        )}
      >
        <div
          className={cn(
            'flex justify-between items-center rounded-lg',
            via_email ? '' : 'p-4 bg-field border border-field',
          )}
        >
          <div className='flex h-4'>
            <SolidEmail className='text-silver relative top-[3px]' size={16} />
            <div className='text-base font-bold pl-3'>Send Email Message</div>
          </div>
          <div className='h-4'>
            <Switch
              name='campaign name'
              className='relative bottom-1'
              checked={!!via_email}
              disabled={disabled}
              onChange={c => handleFormDataChange('via_email', c ? 1 : 0)}
            />
          </div>
        </div>
        {!!via_email && (
          <ContentFormEmail
            emailSubject={email_subject}
            emailImageUrl={email_image_url}
            emailHeadline={email_headline}
            emailContent={email_content}
            enableCta={enable_cta}
            couponCode={campaign_offer?.coupons?.[0].code || ''}
            offerType={campaign_offer?.type || ''}
            disabled={disabled}
            handleFormDataChange={handleFormDataChange}
          />
        )}
      </div>
      <div
        className={cn(
          'mb-6 rounded-lg',
          via_sms ? 'border border-zinc p-4' : '',
        )}
      >
        <div
          className={cn(
            'flex justify-between items-center rounded-lg',
            via_sms ? '' : 'p-4 bg-field border border-field',
          )}
        >
          <div className='flex h-4'>
            <SolidSmsText
              className='text-silver relative top-[3px]'
              size={16}
            />
            <div className='text-base font-bold pl-3'>Send Text Message</div>
          </div>
          <div className='h-4'>
            <Switch
              name='campaign name'
              className='relative bottom-1'
              checked={!!via_sms}
              disabled={disabled}
              onChange={c => {
                if (c && !hasPhone) {
                  setOpenSMSModal(true)
                } else {
                  handleFormDataChange('via_sms', c ? 1 : 0)
                }
              }}
            />
          </div>
        </div>
        {!!via_sms && (
          <ContentFormText
            smsContent={sms_content}
            enableCta={enable_cta}
            viewOnly={viewOnly}
            handleFormDataChange={handleFormDataChange}
          />
        )}
      </div>
      <div
        className={cn(
          'p-4 mb-6 rounded-lg bg-field flex justify-between items-center',
        )}
      >
        <div className='flex h-4'>
          <SolidLink className='text-silver relative top-[3px]' size={16} />
          <div className='text-ink text-base font-bold pl-3'>
            Include “Order Now” Button
            <span className='text-xs text-lead font-normal pl-1'>
              (This may get overridden by some setting in “Attach Offer” step.)
            </span>
          </div>
        </div>
        <div className='h-4'>
          <Switch
            name='campaign name'
            className='relative bottom-1'
            checked={!!enable_cta}
            disabled={disabled}
            onChange={c => handleFormDataChange('enable_cta', c ? 1 : 0)}
          />
        </div>
      </div>
      <Line className='my-6' />
      <Button
        color='primary'
        className='w-full mb-8'
        onClick={() => createOrUpdate()}
        loading={stepLoading}
      >
        NEXT STEP
      </Button>
      <ModalFull
        open={openSMSModal}
        title='Marketing Phone Setting Required'
        toggle={() => {
          setOpenSMSModal(!openSMSModal)
        }}
        okText='SAVE & GO TO SETTING'
        onCancel={() => setOpenSMSModal(false)}
        onOk={goBusinessSetting}
        loading={stepLoading}
      >
        <div className='text-base text-ink text-center leading-6'>
          Before we can continue this campaign and send text messages, let’s
          first grab a phone number that can do that for you.
        </div>
      </ModalFull>
    </div>
  )
}
