import { PropsWithChildren, createContext, useContext } from 'react'

import type { TFormContextType } from 'types/campaign'

import { DEFAULT_FORM_DATA } from './constant'

export const formContext = createContext<TFormContextType>({
  type: '',
  viewOnly: false,
  formData: DEFAULT_FORM_DATA,
  stepLoading: false,
  addOpenData: {
    step: 0,
    type: '',
    typeName: '',
  },
  createOrUpdate: () => null,
  handleFormDataChange: () => null,
  prevStep: () => null,
})

type TFormProviderProps = PropsWithChildren<TFormContextType>

export function FormProvider(props: TFormProviderProps) {
  const { children, ...defaultValues } = props

  return (
    <formContext.Provider value={defaultValues}>
      {children}
    </formContext.Provider>
  )
}

export function useFormContext() {
  const values = useContext(formContext)
  if (!values) {
    throw new Error('Missing FormContext.Provider in the component tree')
  }

  return values
}
