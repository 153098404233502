import { Percentage } from '@rushable/icons'
import Field from 'components/Field'
import { Select } from 'components/Select'
import type { TCampaignCoupon } from 'types/campaign'

import { DEAL_OFFER_OPTIONS } from '../../helpers/constant'

type TCouponRowProps = {
  disabled?: boolean
  coupon: TCampaignCoupon
  couponIndex: number
  handleFormDataChange: (type: string, value: any) => void
}

export default function CouponRow({
  disabled,
  coupon,
  couponIndex,
  handleFormDataChange,
}: TCouponRowProps) {
  const couponIndexStr = `coupons[${couponIndex}]`
  return (
    <div className='flex mb-6'>
      <Select
        label={`Deal offer ${couponIndex + 1}`}
        className='flex-1'
        options={DEAL_OFFER_OPTIONS}
        placeholder='Select'
        value={coupon.type || ''}
        disabled={disabled}
        onChange={nowType =>
          handleFormDataChange(couponIndexStr, {
            newValue: nowType,
            key: 'type',
          })
        }
      />
      {coupon.type === 'flat' || !coupon.type ? (
        <Field
          align='right'
          label='Amount'
          name='text'
          containerClassName='flex-1 mx-6'
          placeholder='0.00'
          suffix='$'
          type='text'
          inputMode='money'
          value={coupon.amount_off || ''}
          disabled={disabled}
          onChangeMoney={e =>
            handleFormDataChange(couponIndexStr, {
              newValue: e,
              key: 'amount_off',
            })
          }
        />
      ) : null}
      {coupon.type === 'percentage' && (
        <Field
          label='Percentage'
          containerClassName='flex-1 mx-6'
          name='percentage text'
          align='right'
          append={<Percentage size={16} className='text-dark-32' />}
          type='text'
          value={coupon.percentage_off || ''}
          disabled={disabled}
          onChange={e =>
            handleFormDataChange(couponIndexStr, {
              newValue: e.target.value,
              key: 'percentage_off',
            })
          }
        />
      )}
      <Field
        align='right'
        label='Order minimum'
        containerClassName='flex-1'
        name='text'
        placeholder='0.00'
        suffix='$'
        type='text'
        inputMode='money'
        value={coupon.threshold || ''}
        disabled={disabled}
        onChangeMoney={e =>
          handleFormDataChange(couponIndexStr, {
            newValue: e,
            key: 'threshold',
          })
        }
      />
    </div>
  )
}
