import Field from 'components/Field'
import { Select } from 'components/Select'
import Switch from 'components/Switch'
import type { TCampaignItem } from 'types/campaign'

import {
  AUDIENCE_TYPES,
  CONDITIONAL_TYPES,
  DATE_TYPES,
} from '../../helpers/constant'

type TAudienceFormProps = {
  disabled?: boolean
  formData: TCampaignItem
  handleFormDataChange: (type: string, value: any) => void
}

export default function AudienceForm({
  disabled,
  formData,
  handleFormDataChange,
}: TAudienceFormProps) {
  const { campaign_audience, type: campaignType } = formData
  const type = campaign_audience?.type || ''
  const conditionalType = campaign_audience?.conditional_type || ''
  const lifetimeOrderCountOperator =
    campaign_audience?.lifetime_order_count_operator || ''
  const lifetimeOrderCount = campaign_audience?.lifetime_order_count || ''
  const recentOrderWithinUnit =
    campaign_audience?.recent_order_within_unit || ''
  const recentOrderWithin = campaign_audience?.recent_order_within || ''
  const includeWithoutOrder = campaign_audience?.include_without_order || 0

  const includeRender = () => {
    return (
      <Switch
        label='Include customers without order history'
        position='left'
        className='mt-4'
        name='include_without_order'
        checked={includeWithoutOrder === 1}
        onChange={v =>
          handleFormDataChange('campaign_audience', {
            key: 'include_without_order',
            newValue: v ? 1 : 0,
          })
        }
      />
    )
  }

  return (
    <>
      <div className='text-base text-ink font-bold pb-2'>Audience</div>
      {campaignType === 'automated' ? (
        <>
          <div className='h-[40px] px-4 flex items-center bg-ice rounded-lg'>
            Target all my subscribed customers
          </div>
          {includeRender()}
        </>
      ) : (
        <>
          <Select
            options={AUDIENCE_TYPES}
            placeholder='Select'
            value={type}
            disabled={disabled}
            onChange={value => {
              handleFormDataChange('campaign_audience', {
                key: 'type',
                newValue: value,
              })
            }}
          />
          {type === 'all' && includeRender()}
          {type === 'conditional' && (
            <div className='flex mt-4 items-center'>
              <Select
                className='w-[180px]'
                options={CONDITIONAL_TYPES}
                placeholder='Select'
                value={conditionalType}
                hideIndicator={true}
                disabled={disabled}
                onChange={value => {
                  handleFormDataChange('campaign_audience', {
                    key: 'conditional_type',
                    newValue: value,
                  })
                }}
              />
              {conditionalType === 'lifetime_order_count' && (
                <div className='flex items-center'>
                  <div className='w-[2px] h-[16px] bg-zinc mx-4' />
                  <div className='flex items-center text-base text-ink'>
                    Placed
                    <Select
                      className='mx-2 w-[74px]'
                      options={[
                        { value: 'more', label: 'More' },
                        { value: 'less', label: 'Less' },
                      ]}
                      placeholder='Select'
                      value={lifetimeOrderCountOperator}
                      hideIndicator={true}
                      disabled={disabled}
                      onChange={value => {
                        handleFormDataChange('campaign_audience', {
                          key: 'lifetime_order_count_operator',
                          newValue: value,
                        })
                      }}
                    />
                    than
                    <div
                      className='mx-2'
                      style={{
                        width:
                          typeof lifetimeOrderCount === 'string' &&
                          lifetimeOrderCount.length > 3
                            ? `${(lifetimeOrderCount.length - 3) * 9 + 64}px`
                            : '64px',
                      }}
                    >
                      <Field
                        name='lifetime_order_count'
                        type='text'
                        className='w-full text-right'
                        value={lifetimeOrderCount}
                        disabled={disabled}
                        onChange={e => {
                          handleFormDataChange('campaign_audience', {
                            key: 'lifetime_order_count',
                            newValue: e.target.value,
                          })
                        }}
                      />
                    </div>
                    order(s) in lifetime.
                  </div>
                  <div></div>
                </div>
              )}
              {conditionalType === 'recent_order_within' && (
                <div className='flex items-center'>
                  <div className='w-[2px] h-[16px] bg-zinc mx-4' />
                  <div className='flex items-center text-base text-ink'>
                    Ordered within
                    <div
                      className='mx-2'
                      style={{
                        width:
                          typeof recentOrderWithin === 'string' &&
                          recentOrderWithin.length > 3
                            ? `${(recentOrderWithin.length - 3) * 9 + 64}px`
                            : '64px',
                      }}
                    >
                      <Field
                        name='recent_order_within'
                        type='text'
                        className='w-full text-right'
                        value={recentOrderWithin}
                        disabled={disabled}
                        onChange={e => {
                          handleFormDataChange('campaign_audience', {
                            key: 'recent_order_within',
                            newValue: e.target.value,
                          })
                        }}
                      />
                    </div>
                    <Select
                      className='mx-2 w-[100px]'
                      options={DATE_TYPES}
                      placeholder='Select'
                      value={recentOrderWithinUnit}
                      hideIndicator={true}
                      disabled={disabled}
                      onChange={value => {
                        handleFormDataChange('campaign_audience', {
                          key: 'recent_order_within_unit',
                          newValue: value,
                        })
                      }}
                    />
                    recently.
                  </div>
                  <div></div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}
