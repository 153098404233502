import { AngleRight, LinedTrash } from '@rushable/icons'
import Button from 'components/Button'
import type { TAddOpenDataState } from 'types/campaign'

import { useFormContext } from '../helpers/context'

type TPageRightTitleProps = {
  pageLoading: boolean
  setAddOpenData: (newAddOpenData: TAddOpenDataState) => void
  setShowDeleteModal: (show: boolean) => void
}

// 标题右侧按钮
export default function PageRightTitle({
  pageLoading,
  setAddOpenData,
  setShowDeleteModal,
}: TPageRightTitleProps) {
  const { type, formData, addOpenData, handleFormDataChange } = useFormContext()
  const status = formData.status
  const typeName = addOpenData.typeName
  let ele = null
  if (type === 'automated') {
    if (status !== 'draft' && status !== 'scheduled') {
      ele = (
        <>
          <Button
            color='secondary-link'
            onClick={() => {
              if (!pageLoading) {
                handleFormDataChange('status', 'scheduled')
                setAddOpenData({ ...addOpenData, step: 1 })
              }
            }}
          >
            CAMPAIGN SETTING
            <AngleRight size={16} />
          </Button>
        </>
      )
    } else {
      ele = <>{typeName}</>
    }
  }
  if (type === 'one_off' && formData.id) {
    if (status === 'draft' || status === 'scheduled') {
      ele = (
        <Button color='secondary-link' onClick={() => setShowDeleteModal(true)}>
          <LinedTrash size={16} className='text-silver cursor-pointer' />
          <span className='text-silver cursor-pointer text-base ml-1.5'>
            DELETE
          </span>
        </Button>
      )
    } else {
      ele = (
        <>
          <Button
            color='secondary-link'
            onClick={() => {
              if (!pageLoading) {
                handleFormDataChange('status', 'scheduled')
                setAddOpenData({ ...addOpenData, step: 1, viewOnly: true })
              }
            }}
          >
            VIEW DETAIL
            <AngleRight size={16} />
          </Button>
        </>
      )
    }
  }
  return ele
}
