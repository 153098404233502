import AutomatedCampaignPage from 'pages/outboundCampaign/AutomatedCampaignPage'
import OneOffCampaignPage from 'pages/outboundCampaign/OneOffCampaignPage'
import type { TCampaignItem } from 'types/campaign'

export const ALL_STATUS_OPTIONS = [
  { value: '', label: 'All Status' },
  { value: 'draft', label: 'Draft' },
  { value: 'scheduled', label: 'Scheduled' },
  { value: 'processing', label: 'Processing' },
  { value: 'paused', label: 'Paused' },
  { value: 'complete', label: 'Sent' },
]

export const OFFER_ITEMS = [
  { value: '', label: 'No' },
  {
    value: 'lucky_spin',
    label: 'Play a “Lucky Spin” game to win an offer (100% winning ratio)',
  },
  {
    value: 'coupon_code',
    label: 'Display a coupon code',
  },
]

export const DEAL_OFFER_OPTIONS = [
  { value: 'flat', label: 'Flat amount off' },
  { value: 'percentage', label: 'Percentage rate' },
]

export const AUDIENCE_TYPES = [
  { value: 'all', label: 'Target all my subscribed customers' },
  { value: 'conditional', label: 'Target customers with condition' },
]

export const CONDITIONAL_TYPES = [
  { value: 'lifetime_order_count', label: 'Lifetime order count' },
  { value: 'recent_order_within', label: 'Recent order date' },
]

export const DATE_TYPES = [
  { value: 'day', label: 'Days' },
  { value: 'week', label: 'Weeks' },
  { value: 'year', label: 'Years' },
]

const ONE_OFF_CAMPAIGN: TRouteType = {
  path: 'one-off-campaign',
  name: 'ONEOFFCAMPAIGN',
  label: 'One-Off Campaign',
  component: OneOffCampaignPage,
}
const AUTOMATED_CAMPAIGN: TRouteType = {
  path: 'automated-campaign',
  name: 'AUTOMATEDCAMPAIGN',
  label: 'Automated Campaign',
  component: AutomatedCampaignPage,
}

export const DEFAULT_FORM_DATA: TCampaignItem = {
  id: null,
  campaign_template_id: 0,
  trigger_of_inactive_days: null,
  type: '',
  status: 'draft',
  current_step: 0,
  name: '',
  via_email: 0,
  via_sms: 0,
  enable_cta: 1,
  email_subject: '',
  email_image_file: '',
  email_image_url: '',
  email_headline: '',
  email_content: '',
  sms_content: '',
  percent: 0,
  campaign_offer: {
    type: '',
    name: '',
    valid_days: 7,
  },
  trigger_of_date_time: '',
  trigger_type: '',
  campaign_audience: null,
  payment: null,
}

export const outboundCampaignRoutes = [ONE_OFF_CAMPAIGN, AUTOMATED_CAMPAIGN]
