import React from 'react'

import cn from 'classnames'
import { useAppSelector } from 'redux/hooks'
import { isProPackageName } from 'routes/helpers/tool'

type TNavItemProp = {
  label?: string
  value: string
  lv?: 1 | 2
  className?: string
  active?: boolean
  disabled?: boolean
  icon?: React.ReactElement
  onClick?: (value: string) => void
}

export default function NavItem({
  label,
  value,
  className = '',
  onClick,
  active = false,
  lv = 1,
  disabled = false,
  icon,
  ...arg
}: TNavItemProp): JSX.Element {
  const { selectedBrand } = useAppSelector(state => state.brand)
  if (disabled) {
    return <></>
  }
  const hasProIcon =
    !!label &&
    !selectedBrand?.has_pro_package &&
    isProPackageName(label, selectedBrand)
  return (
    <a
      className={cn(
        'relative flex items-center rounded-lg text-white py-3 text-sm hover:bg-light-8 cursor-pointer',
        lv === 1 ? 'px-2' : 'pl-8 pr-4',
        active ? '!bg-light-16' : '',
        // { hidden: disabled },
        className,
      )}
      onClick={() => onClick && onClick(value)}
      {...arg}
    >
      {icon && (
        <div
          className={`mr-2 flex items-center ${
            active ? 'text-green' : 'text-light-64'
          }`}
        >
          {icon}
        </div>
      )}
      <div className='whitespace-nowrap'>{label}</div>
      {hasProIcon && (
        <div className='text-xxs-l bg-white px-2 py-1 rounded-lg text-ink font-bold absolute right-[-22px]'>
          PRO
        </div>
      )}
    </a>
  )
}
