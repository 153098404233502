import { AngleDown } from '@rushable/icons'
import type { TCampaignCoupon } from 'types/campaign'

type TCouponCodeFormProps = {
  disabled?: boolean
  coupons: TCampaignCoupon[]
  setOpenDrawer: (open: boolean) => void
}

export default function CouponCodeForm({
  disabled,
  coupons,
  setOpenDrawer,
}: TCouponCodeFormProps) {
  return (
    <div className='mt-6 p-6 border border-dark-16 rounded-lg'>
      <div className='block w-full'>
        <div className='flex justify-between'>
          <span className='text-xs font-bold text-silver'>Coupon code</span>
        </div>
        <div
          className='block relative cursor-pointer'
          onClick={() => !disabled && setOpenDrawer(true)}
        >
          <div className='transition text-base outline-none h-[46px] relative flex flex-1 w-full hover:outline-none border border-solid mt-2 border-slate rounded-b-lg rounded-t-lg text-ink bg-field ring-slate pl-4 pr-9'>
            {coupons[0]?.code ? (
              <span className='text-ink py-3'>{coupons[0]?.code}</span>
            ) : (
              <span className='text-dark-16 py-3'>Select</span>
            )}
            <div className='absolute top-0 right-4 h-10 flex flex-1 items-center transition duration-75'>
              <AngleDown size={16} className='text-silver' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
