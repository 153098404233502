import React, { useState } from 'react'

import { SolidCopy, Check } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import useCopyToClipboard from 'hooks/useCopyToClipboard'

type TCopyBoxProps = {
  className?: string
  text: string
  copyText: string
  disabled?: boolean
}

export default function CopyBox({
  className,
  text,
  copyText,
  disabled,
}: TCopyBoxProps) {
  const [isCopy, setIsCopy] = useState(false)
  const [, copy] = useCopyToClipboard()

  const handleCopyText = (e: React.MouseEvent) => {
    if (disabled) {
      return
    }
    e.stopPropagation()
    copy(copyText)
    setIsCopy(true)
    setTimeout(() => setIsCopy(false), 2500)
  }
  return (
    <div
      className={cn(
        'h-12 px-4 flex justify-between items-center border-2 rounded-lg  ',
        isCopy ? 'border-blue' : 'border-zinc',
        disabled
          ? 'border-ice bg-ice cursor-no-drop'
          : 'bg-white cursor-pointer hover:border-blue',
        className,
      )}
      onClick={handleCopyText}
    >
      <span
        className={cn(
          'text-base font-normal',
          disabled ? 'text-dark-16' : 'text-dark-100',
        )}
      >
        {text}
      </span>
      <Button color='secondary-link' className='relative'>
        {isCopy ? (
          <>
            <span className='absolute text-blue right-full p-2 z-50 rounded-lg'>
              Copied
            </span>
            <Check className='text-blue' size={16} />
          </>
        ) : (
          <SolidCopy
            className={cn(disabled ? 'text-dark-16' : 'text-blue')}
            size={16}
          />
        )}
      </Button>
    </div>
  )
}
