import Field from 'components/Field'
import type { TCampaignCoupon } from 'types/campaign'

import CouponRow from './CouponRow'

type TLuckySpinFormProps = {
  coupons: TCampaignCoupon[]
  disabled?: boolean
  validDays: number | undefined
  handleFormDataChange: (type: string, value: any) => void
}

export default function LuckySpinForm({
  coupons,
  disabled,
  validDays,
  handleFormDataChange,
}: TLuckySpinFormProps) {
  return (
    <>
      <div className='mt-4 p-6 border border-zinc rounded-lg'>
        <CouponRow
          disabled={disabled}
          coupon={coupons[0]}
          couponIndex={0}
          handleFormDataChange={handleFormDataChange}
        />
        <CouponRow
          disabled={disabled}
          coupon={coupons[1]}
          couponIndex={1}
          handleFormDataChange={handleFormDataChange}
        />
      </div>
      <div className='mt-4 px-6 py-4 border border-zinc rounded-lg flex items-center'>
        Allow customers to redeem the promo offer within
        <Field
          name='count'
          align='right'
          containerClassName='mx-2 w-[64px]'
          type='text'
          value={validDays}
          disabled={disabled}
          onChange={e => {
            handleFormDataChange('valid_days', e.target.value)
          }}
        />
        days
      </div>
    </>
  )
}
