import { useState } from 'react'

import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import { useParams, useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { deleteOneOffApi } from 'redux/campaign'

import { useFormContext } from '../helpers/context'

interface IModalTypes {
  open: boolean
  toggle: () => void
}

export default function DeleteCampaignModal({
  open,
  toggle,
}: IModalTypes): JSX.Element {
  const history = useHistory()
  const { formData: data } = useFormContext()

  const { brandId, locationId, id } = useParams<any>()
  const [isLoading, setLoading] = useState(false)
  const [formError, setFormError] = useState('')
  const [name, setName] = useState('')

  // one_off 活动删除 api
  const deleteOneOff = async () => {
    setLoading(true)
    setFormError('')
    try {
      const res = await deleteOneOffApi(id, locationId)
      toast.success(res.message)
      toggle()
      history.push(`/brand/${brandId}/location/${locationId}/outbound-campaign`)
    } catch (err: any) {
      setFormError(err.message)
    }
    setLoading(false)
  }

  return (
    <ModalFull
      open={open}
      toggle={toggle}
      error={formError}
      title='Delete Campaign'
      okText='CONFIRM'
      okBtnColor='warning'
      okBtnDisabled={name !== 'Delete'}
      loading={isLoading}
      onOk={deleteOneOff}
    >
      <>
        <div className='text-base leading-[24px] text-ink text-center'>
          This action will delete “{data.name}”. You will not be able to restore
          this after the deletion.
        </div>
        <Field
          type='text'
          name='Type “Delete” below to confirm this action'
          containerClassName='my-6'
          placeholder='Delete'
          label={
            <span className='font-bold text-ink'>
              Type “Delete” below to confirm this action
            </span>
          }
          value={name || ''}
          onChange={e => {
            setName(e.target.value)
          }}
        />
      </>
    </ModalFull>
  )
}
