import React, { useState, useEffect } from 'react'

import { LinedSearch } from '@rushable/icons'
import campaignNonePng from 'assets/img/campaign-none.png'
import Button from 'components/Button'
import Field from 'components/Field'
import { Spin } from 'components/Loading'
import Pagination from 'components/Pagination'
import { Select } from 'components/Select'
import useDebounce from 'hooks/useDebounce'
import moment from 'moment'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { getCampaigns, getCampaignCount } from 'redux/campaign'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import type { TCampaignItem, TAddOpenDataState } from 'types/campaign'

import OneOffItem from './components/OneOffItem'
import Skeleton from './components/Skeleton'

type TOneOffCampaignPageProp = {
  setAddOpenData: (data: TAddOpenDataState) => void
}

export default function OneOffCampaignPage({
  setAddOpenData,
}: TOneOffCampaignPageProp): JSX.Element {
  const history = useHistory()
  const [keyword, setKeyword] = useState('')
  const [status, setStatus] = useState('')
  const [page, setPage] = useState(0)
  const dispatch = useAppDispatch()
  const { getCampaignsRequest, campaigns } = useAppSelector(
    state => state.campaign,
  )
  const [statusOptionsWithCount, setStatusOptionsWithCount] = useState<
    {
      value: string
      label: string
    }[]
  >([])
  const { brandId, locationId } =
    useParams<{ brandId: string; locationId: string }>()
  const handleStatusChange = (nowStatus: string) => {
    setStatus(nowStatus)
    handleGetCampaign({
      page: 1,
      status: nowStatus,
      keyword,
    })
  }
  const handleKeywordChange = useDebounce((newKeyword: string, status) => {
    handleGetCampaign({
      page: 1,
      keyword: newKeyword,
      status,
    })
  })
  const handleGetCampaign = ({
    page,
    keyword = '',
    status = '',
  }: {
    page: number
    keyword?: string
    status?: string
  }) => {
    setPage(page)
    dispatch(
      getCampaigns({
        locationId,
        type: 'one_off',
        page,
        keyword,
        status,
      }),
    )
  }
  const goDetail = (item: TCampaignItem) => {
    setAddOpenData({
      step:
        item.status === 'draft' || item.status === 'scheduled'
          ? Number(item.current_step)
          : 5,
      type:
        item.status === 'draft' || item.status === 'scheduled'
          ? 'one_off'
          : item.name,
      typeName:
        item.status === 'draft' || item.status === 'scheduled'
          ? ''
          : `Sent on ${moment(item.trigger_of_date_time).format(
              'MMM DD, YYYY',
            )}`,
      item,
    })
    history.push(
      `/brand/${brandId}/location/${locationId}/outbound-campaign/campaign-detail/${item.id}`,
    )
  }
  const handleGetCampaignCount = async () => {
    const res = await dispatch(getCampaignCount(locationId))
    if (res) {
      setStatusOptionsWithCount([
        { value: '', label: `All Status (${res.all})` },
        { value: 'draft', label: `Draft (${res.draft})` },
        { value: 'scheduled', label: `Scheduled (${res.scheduled})` },
        { value: 'processing', label: `Processing (${res.processing})` },
        { value: 'paused', label: `Paused (${res.paused})` },
        { value: 'complete', label: `Sent (${res.complete})` },
      ])
    }
  }
  useEffect(() => {
    handleGetCampaign({
      page: 1,
    })
    handleGetCampaignCount()
  }, [])
  return (
    <div className='pt-8'>
      <Spin
        spining={
          getCampaignsRequest && page === 1 && campaigns.data.length === 0
        }
        className='rounded-b-lg'
        styles={{
          minHeight: 'calc(100vh - 144px)',
        }}
      >
        <>
          <div className='flex justify-between pb-8'>
            <div className='flex'>
              <Field
                className='w-[240px] mr-6'
                name='Search One Off Campaign'
                type='text'
                placeholder='Search'
                value={keyword}
                onChange={e => {
                  setKeyword(e.target.value)
                  handleKeywordChange(e.target.value, status)
                }}
                suffix={<LinedSearch className='text-silver' size={16} />}
              />
              <Select
                className='w-[240px]'
                options={statusOptionsWithCount}
                placeholder='Select'
                value={status}
                onChange={nowStatus => handleStatusChange(nowStatus)}
              />
            </div>
            <div>
              <Button
                color='primary'
                className='ring-0'
                onClick={() => {
                  setAddOpenData({
                    step: 1,
                    type: 'one_off',
                    typeName: '',
                    create: true,
                  })
                  history.push(
                    `/brand/${brandId}/location/${locationId}/outbound-campaign/campaign-detail/0`,
                  )
                }}
              >
                CREATE NEW CAMPAIGN
              </Button>
            </div>
          </div>
          <div>
            {campaigns.data.length > 0 ? (
              <>
                {getCampaignsRequest ? (
                  <div>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </div>
                ) : (
                  campaigns.data.map((list, index) => (
                    <OneOffItem
                      item={list}
                      key={list.id}
                      noBorder={index === campaigns.data.length - 1}
                      goDetail={goDetail}
                    />
                  ))
                )}
                {campaigns.last_page && campaigns.last_page > 1 ? (
                  <Pagination
                    total={campaigns.total as number}
                    current={campaigns.current_page as number}
                    pageSize={campaigns.per_page as number}
                    onChange={(pageNo: number) => {
                      handleGetCampaign({
                        page: pageNo,
                        keyword,
                        status,
                      })
                    }}
                    className='mt-8'
                  />
                ) : null}
              </>
            ) : null}
            {!getCampaignsRequest &&
            page === 1 &&
            campaigns.data.length === 0 ? (
              <div className='min-h-[560px] flex justify-center items-center flex-col'>
                <div className='text-base text-lead pb-8'>
                  You don’t have any campaign yet...
                </div>
                <img src={campaignNonePng} width={160} height={160} />
              </div>
            ) : null}
          </div>
        </>
      </Spin>
    </div>
  )
}
