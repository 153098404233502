import React from 'react'

import { Google, Yelp, SolidChatComment } from '@rushable/icons'
import CellOrderTypes from 'components/TableAndCell/Cell/CellOrderTypes'
import Impression from 'components/TableAndCell/Cell/Impression'
import Table from 'components/TableAndCell/Table'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setCommentModal } from 'redux/smartReview'
import { TSortData } from 'types/table'

import ReviewModal from './ReviewModal'

type TReviewTableProps = {
  loading: boolean
  page: number
  tableData: any
  handleFormDataChange: (key: string, value: any) => void
  handleSortDataChange: (newSortData: TSortData) => void
  getReviewList: () => void
}

export default function ReviewTable({
  loading,
  page,
  tableData,
  handleFormDataChange,
  handleSortDataChange,
  getReviewList,
}: TReviewTableProps) {
  const dispatch = useAppDispatch()
  const { sortData, commentModal } = useAppSelector(state => state.smartReview)
  const columns = [
    {
      key: 'order',
      name: 'CUSTOMER',
      className: 'w-40',
      custom: (order: any, row: any) => {
        console.log(row, 'row')
        const reviewerName = row?.reviewer_name || '-'
        const customer = order?.customer
        const customerName = `${customer?.first_name} ${customer?.last_name}`
        return (
          <div className='text-ellipsis'>
            {order?.customer ? customerName : reviewerName}
          </div>
        )
      },
    },
    {
      key: 'order_id',
      name: 'ORDER ID',
      align: 'left',
      className: 'w-[110px]',
      custom: (value: any) => {
        return <div>{value ? `#${value}` : '-'}</div>
      },
    },
    {
      key: 'order',
      name: 'TYPE',
      className: 'w-36',
      custom: (order: any) => {
        const type = order?.type
        if (type) {
          return (
            <div className='flex items-center text-lead'>
              <CellOrderTypes type={type} />
            </div>
          )
        }
        return <div>-</div>
      },
    },
    {
      key: 'impression',
      name: 'IMPRESSION',
      align: 'left',
      className: 'w-40',
      custom: (impression: 'bad' | 'good' | undefined, item: any) => {
        const { contacted_by } = item
        const renderText = !impression ? (
          'Not yet rated'
        ) : impression === 'bad' && !!contacted_by ? (
          <>
            Bad <span className='font-normal'>(contacted)</span>
          </>
        ) : (
          ''
        )
        return <Impression impression={impression} renderText={renderText} />
      },
    },
    {
      key: 'platform',
      name: 'REVIEW',
      tdClassName: 'truncate',
      custom: (platform: string, item: any) => {
        const impression = item?.impression
        const comment = item?.comment
        if (impression) {
          return (
            <div className='w-full flex items-center text-sm text-dark-100 '>
              {platform === 'google' ? (
                <Google size={16} className='mr-1' />
              ) : platform === 'yelp' ? (
                <Yelp size={16} className='mr-1' />
              ) : impression === 'bad' ? (
                <SolidChatComment className='text-silver mr-1' size={16} />
              ) : null}
              <span className='truncate'>{comment}</span>
            </div>
          )
        }
        return <div>-</div>
      },
    },
    {
      key: 'sent_at',
      name: 'INVITE DATE',
      align: 'right',
      className: 'w-32',
      sortable: true,
      sortDirection: sortData.sort_by === 'sent_at' ? sortData.sort : '',
      onSortDirection: (value: string) => {
        handleSortDataChange({
          sort_by: 'sent_at',
          sort: value,
        })
      },
      custom: (sent_at: string) => {
        return <div>{moment(sent_at).format('MM/DD/YYYY')}</div>
      },
    },
  ]
  return (
    <>
      <Table
        loading={loading}
        isPageSet={true}
        current={page}
        className=''
        containerClassName='text-xs text-ink rounded-lg border border-solid border-zinc overflow-hidden'
        columns={columns}
        data={tableData.data}
        total={tableData.total}
        totalText={'results'}
        onPageNoChange={value => handleFormDataChange('page', value)}
        onPageSizeChange={value => {
          handleFormDataChange('pageSize', value)
        }}
        clickedRow={(row: any) =>
          dispatch(
            setCommentModal({
              open: true,
              data: row,
            }),
          )
        }
      />
      <ReviewModal
        commentModal={commentModal}
        onClose={() =>
          dispatch(
            setCommentModal({
              open: false,
              data: null,
            }),
          )
        }
        getReviewList={getReviewList}
      />
    </>
  )
}
