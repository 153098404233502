import { useState } from 'react'

import { SELECTRESTAURANT } from 'data/routes'
import RequireAuth from 'pages/auth/RequireAuth'
import NoMatch from 'pages/error/NoMatch'
import SimpleLayout from 'pages/layout/SimpleLayout'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { setNextRoute, setProPackageModalOpen } from 'redux/brand'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { isProPackagePath } from 'routes/helpers/tool'

import { pageRoutes } from './index'

const childRoutes = (routes: Array<TRouteType>) => {
  const dispatch = useAppDispatch()
  const { selectedBrand } = useAppSelector(state => state.brand)
  const [showProModal, setShowProModal] = useState(false)

  const handleValidation = (toPath: string): any => {
    // 进行验证 pro package
    if (
      !!selectedBrand &&
      !selectedBrand?.has_pro_package &&
      isProPackagePath(toPath, selectedBrand)
    ) {
      if (!showProModal) {
        // 保证 proPackageModal cancel 后不再重复打开
        setShowProModal(true)
        dispatch(setNextRoute(window.location.pathname))
        dispatch(setProPackageModalOpen(true))
      }
    }
  }
  return routes.map(
    ({
      component: Component,
      path,
      auth,
      layout: Layout,
      permissionId,
      showBreadCrumbs,
    }) => {
      const needProModal =
        !!selectedBrand &&
        !selectedBrand?.has_pro_package &&
        isProPackagePath(path, selectedBrand)
      const container = Layout ? (
        <Layout permissionId={permissionId} showBreadCrumbs={showBreadCrumbs}>
          {needProModal ? <></> : <Component />}
        </Layout>
      ) : (
        <Component />
      )
      const comp = auth ? <RequireAuth>{container}</RequireAuth> : container
      return (
        <Route
          key={path}
          path={path}
          render={() => handleValidation(path) || comp}
        />
      )
    },
  )
}

export default (): JSX.Element => {
  return (
    <BrowserRouter>
      <Switch>
        {childRoutes(pageRoutes)}
        <Route exact path='/' children={<Redirect to={SELECTRESTAURANT} />} />
        <Redirect
          exact
          from='/brand/:brandId/location/:locationId'
          to='/brand/:brandId/location/:locationId/order-history'
        />
        <Route path='*'>
          <SimpleLayout>
            <NoMatch />
          </SimpleLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
