import { useState, useEffect } from 'react'

import AdminPageTitle from 'components/AdminPageTitle'
import Timeline from 'components/Timeline'
import useDebounce, { compare } from 'hooks/useDebounce'
import cloneDeep from 'lodash/cloneDeep'
import { useParams, useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { createCampaigns, updateCampaigns, showCampaign } from 'redux/campaign'
import { useAppDispatch } from 'redux/hooks'
import type { TCampaignItem, TAddOpenDataState } from 'types/campaign'

import AttachOfferForm from './components/AttachOfferForm'
import ContentForm from './components/ContentForm'
import DeleteCampaignModal from './components/DeleteCampaignModal'
import FinalReviewForm from './components/FinalReviewForm'
import OverviewForm from './components/OverviewForm'
import PageCenterTitle from './components/PageCenterTitle'
import PageLeftTitle from './components/PageLeftTitle'
import PageRightTitle from './components/PageRightTitle'
import SendingRulesForm from './components/SendingRulesForm'
import { OFFER_ITEMS, DEFAULT_FORM_DATA } from './helpers/constant'
import { FormProvider } from './helpers/context'

type TFormCampaignPageProps = {
  addOpenData: TAddOpenDataState
  setAddOpenData: any
}

export default function FormCampaignPage({
  addOpenData,
  setAddOpenData,
}: TFormCampaignPageProps): JSX.Element {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { type, step } = addOpenData
  const { brandId, locationId, id } =
    useParams<{ brandId: string; locationId: string; id: string | undefined }>()
  const [formData, setFormData] = useState<TCampaignItem>({
    ...DEFAULT_FORM_DATA,
    type,
    current_step: step,
  })
  const [stepLoading, setStepLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const compareFn = useDebounce(compare)

  const handleFormDataChange = (type: string, value: any) => {
    if (type === 'email_image') {
      setFormData({
        ...formData,
        email_image_file: value.blob,
        email_image_url: value.preview,
      })
      return
    }
    if (type === 'email_image_url') {
      setFormData({
        ...formData,
        email_image_file: '',
        email_image_url: value,
      })
      return
    }
    if (type === 'remove_image') {
      setFormData({
        ...formData,
        email_image_file: '',
        email_image_url: '',
      })
      return
    }
    if (type === 'campaign_offer_type') {
      setFormData({
        ...formData,
        campaign_offer: {
          type: value,
          name: OFFER_ITEMS.find(v => v.value === value)?.label || '',
          coupons: [
            {
              type: '',
              threshold: '',
              description: '',
              amount_off: '',
              percentage_off: '',
            },
            {
              type: '',
              threshold: '',
              description: '',
              amount_off: '',
              percentage_off: '',
            },
          ],
          valid_days: 7,
        },
      })

      return
    }
    if (type === 'coupons[0]') {
      const { key, newValue }: { key: string; newValue: string } = value
      setFormData({
        ...formData,
        campaign_offer: {
          type: formData.campaign_offer?.type as string,
          name: formData.campaign_offer?.name as string,
          coupons: formData?.campaign_offer?.coupons?.map((item, index) => {
            if (index === 0) {
              if (key === 'type') {
                return {
                  type: newValue as 'flat' | 'percentage' | 'free_item' | '',
                  threshold: '',
                  description: '',
                  amount_off: '',
                  percentage_off: '',
                }
              }
              return {
                ...item,
                [key]: newValue,
              }
            }
            return item
          }),
          valid_days: formData.campaign_offer?.valid_days as number,
        },
      })
      return
    }
    if (type === 'coupons[1]') {
      const { key, newValue }: { key: string; newValue: string } = value
      setFormData({
        ...formData,
        campaign_offer: {
          type: formData.campaign_offer?.type as string,
          name: formData.campaign_offer?.name as string,
          coupons: formData?.campaign_offer?.coupons?.map((item, index) => {
            if (index === 1) {
              if (key === 'type') {
                return {
                  type: newValue as 'flat' | 'percentage' | 'free_item' | '',
                  threshold: '',
                  description: '',
                  amount_off: '',
                  percentage_off: '',
                }
              }
              return {
                ...item,
                [key]: newValue,
              }
            }
            return item
          }),
          valid_days: formData.campaign_offer?.valid_days as number,
        },
      })
      return
    }
    if (type === 'valid_days' && formData.campaign_offer) {
      setFormData({
        ...formData,
        campaign_offer: {
          ...formData.campaign_offer,
          valid_days: Number(value),
        },
      })
      return
    }
    if (type === 'coupon_id' && formData.campaign_offer) {
      setFormData({
        ...formData,
        campaign_offer: {
          ...formData.campaign_offer,
          coupons: [
            {
              id: value.id,
              code: value.code,
              type: '',
              threshold: '',
              description: '',
              amount_off: '',
              percentage_off: '',
            },
          ],
        },
      })
      return
    }
    if (type === 'trigger_of_date') {
      setFormData({
        ...formData,
        trigger_of_date_time: `${value}${
          formData.trigger_of_date_time &&
          formData.trigger_of_date_time.length > 0
            ? ' ' + formData.trigger_of_date_time.split(' ')[1]
            : ''
        }`,
      })
      return
    }
    if (type === 'trigger_of_time') {
      setFormData({
        ...formData,
        trigger_of_date_time: `${
          formData.trigger_of_date_time &&
          formData.trigger_of_date_time.length > 0
            ? formData.trigger_of_date_time.split(' ')[0]
            : ''
        } ${value}`,
      })
      return
    }
    if (type === 'campaign_audience') {
      const { key, newValue } = value
      if (key === 'type') {
        setFormData({
          ...formData,
          campaign_audience: {
            type: newValue,
            conditional_type: '',
            lifetime_order_count_operator: '',
            lifetime_order_count: '',
            recent_order_within_unit: '',
            recent_order_within: '',
            include_without_order: 0,
          },
        })
        return
      }
      if (key === 'conditional_type') {
        setFormData({
          ...formData,
          campaign_audience: {
            type: formData?.campaign_audience?.type || '',
            conditional_type: newValue,
            lifetime_order_count_operator:
              newValue === 'lifetime_order_count' ? 'more' : '',
            lifetime_order_count:
              newValue === 'lifetime_order_count' ? '1' : '',
            recent_order_within_unit:
              newValue === 'lifetime_order_count' ? '' : 'week',
            recent_order_within: newValue === 'lifetime_order_count' ? '' : '2',
            include_without_order: 0,
          },
        })
        return
      }
      if (formData.campaign_audience) {
        setFormData({
          ...formData,
          campaign_audience: {
            ...formData.campaign_audience,
            [key]: newValue,
          },
        })
        return
      }
    }
    setFormData({
      ...formData,
      [type]: value,
    })
  }
  const createOrUpdate = async (extraParam: Record<string, any> = {}) => {
    if (addOpenData.viewOnly && !extraParam.status) {
      nextStep()
      return
    }
    try {
      setStepLoading(true)
      // update
      if (formData.id) {
        // 只针对 one_off 的 status 做处理
        const status = step < 4 ? 'draft' : formData.status
        const current_step = step >= 4 ? 4 : step + 1
        const res = await dispatch(
          updateCampaigns(
            { ...formData, current_step, status, ...extraParam },
            locationId,
          ),
        )
        if (res && res.id) {
          setFormData({ ...formData, ...res })
          setTimeout(() => {
            setAddOpenData({
              ...addOpenData,
              type: res.type,
              typeName: res.type === 'automated' ? 'Automated Campaign' : '',
              // 从第四步点击 edit campaign 进来
              step: extraParam._client_step ? 1 : res.current_step,
              item: cloneDeep({
                ...res,
              }),
            })
          }, 300)
          if (extraParam.campaign_locations) {
            return
          }
          // 从第四步点击 edit campaign 进来
          if (extraParam._client_step) {
            return
          }
          if (step === 4 && !extraParam.noGoList) {
            const nextUrl =
              res.type === 'one_off'
                ? `/brand/${brandId}/location/${locationId}/outbound-campaign`
                : `/brand/${brandId}/location/${locationId}/outbound-campaign/automated-campaign`
            history.push(nextUrl)
          } else {
            nextStep()
          }
        } else if (res.message) {
          toast.error(res.message)
        }
      } else {
        // create
        const res = await dispatch(
          createCampaigns({ ...formData, current_step: step + 1 }, locationId),
        )
        if (res && res.id) {
          setFormData({ ...formData, ...res })
          setTimeout(() => {
            setAddOpenData({
              ...addOpenData,
              type: res.type,
              typeName: res.type === 'automated' ? 'Automated Campaign' : '',
              step: res.current_step,
              item: cloneDeep({
                ...res,
              }),
            })
          }, 300)
          nextStep()
          const nextUrl = `/brand/${brandId}/location/${locationId}/outbound-campaign/campaign-detail/${res.id}`
          history.push(nextUrl)
        }
      }
    } catch (error: any) {
      toast.error(error.message || 'Request error')
    } finally {
      setStepLoading(false)
    }
  }
  const getShow = async (id: string) => {
    setPageLoading(true)
    try {
      const res = await dispatch(showCampaign(id, locationId as string))
      setFormData({
        ...formData,
        ...res,
      })
      setAddOpenData({
        ...addOpenData,
        type: res.type,
        typeName: res.type === 'automated' ? 'Automated Campaign' : '',
        step: res.current_step,
        item: cloneDeep({
          ...formData,
          ...res,
        }),
      })
      setPageLoading(false)
    } catch (error: any) {
      setPageLoading(false)
      if (error.message) {
        toast.warn(error.message)
      }
    }
  }
  const nextStep = () => {
    setAddOpenData({
      ...addOpenData,
      step: step + 1,
    })
  }
  const prevStep = () => {
    setAddOpenData({
      ...addOpenData,
      step: step - 1,
    })
  }

  useEffect(() => {
    // 不对比 status，只对比其他数据
    // 因为例如 addOpenData.item.status === 'complete' 时，点击 “CAMPAIGN SETTING” 查看设置时，formData。status 会被设置成 “scheduled”
    compareFn(
      // formData 页面渲染数据
      { ...formData, status: '' },
      // addOpenData.item 与 api 返回数据保持一致，只会在 api 获取数据时更新
      { ...addOpenData.item, status: '' },
      (flag: boolean) => {
        const itemType = addOpenData.item?.type
        const itemStatus = addOpenData.item?.status
        if (itemType === 'automated') {
          if (itemStatus !== 'draft' && itemStatus !== 'scheduled') {
            setAddOpenData({
              ...addOpenData,
              viewOnly: !flag,
            })
          } else {
            setAddOpenData({
              ...addOpenData,
              viewOnly: false,
            })
          }
        }
      },
    )
  }, [formData, addOpenData.item])

  useEffect(() => {
    if (!id && !addOpenData.item) {
      history.push(`/brand/${brandId}/location/${locationId}/outbound-campaign`)
      return
    }
    if (id === '0' && !addOpenData.create) {
      history.push(`/brand/${brandId}/location/${locationId}/outbound-campaign`)
      return
    }
    if (addOpenData.item) {
      setFormData({
        ...formData,
        ...addOpenData.item,
      })
    }
    if (id && id !== '0') {
      getShow(id)
    }
  }, [])

  return (
    <FormProvider
      type={type}
      viewOnly={addOpenData.viewOnly}
      formData={formData}
      addOpenData={addOpenData}
      prevStep={prevStep}
      createOrUpdate={createOrUpdate}
      handleFormDataChange={handleFormDataChange}
      stepLoading={stepLoading}
    >
      <div className='flex flex-col items-center'>
        <AdminPageTitle
          className='px-8'
          title={<PageCenterTitle />}
          left={<PageLeftTitle />}
          right={
            <PageRightTitle
              pageLoading={pageLoading}
              setAddOpenData={setAddOpenData}
              setShowDeleteModal={setShowDeleteModal}
            />
          }
        />
        {formData.status === 'draft' || formData.status === 'scheduled' ? (
          <div className='w-720px py-8'>
            <Timeline
              className='mt-4 mb-6'
              current={step - 1}
              list={[
                { label: '1', title: 'Content' },
                { label: '2', title: 'Attach Offer' },
                { label: '3', title: 'Sending Rules' },
                { label: '4', title: 'Final Review' },
              ]}
            />
            {step === 1 && <ContentForm />}
            {step === 2 && <AttachOfferForm />}
            {step === 3 && <SendingRulesForm />}
            {step === 4 && <FinalReviewForm />}
          </div>
        ) : (
          <div>
            <OverviewForm />
          </div>
        )}
        <DeleteCampaignModal
          open={showDeleteModal}
          toggle={() => setShowDeleteModal(false)}
        />
      </div>
    </FormProvider>
  )
}
