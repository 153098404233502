import React, { useState } from 'react'

import { SolidImage } from '@rushable/icons'
import classnames from 'classnames'
import Button from 'components/Button'
import Field from 'components/Field'
import Label from 'components/Label'
import ModalFull from 'components/Modal/ModalFull'
import Upload from 'components/Upload/Upload'
import Waterfall from 'components/WaterFall/WaterFall'
import { toast } from 'react-toastify'
import { getUnsplashPhotosApi } from 'redux/brand/actions'

import UnsplashIcon from './Unsplash'

interface Iprops {
  className?: string
  labelText?: string
  width?: string
  height?: string
  value?: string
  viewOnly?: boolean
  onChange: (value: string, blob?: Blob) => void
  onRemove?: () => void
}

const SEARCHICON = (
  <svg
    fill='none'
    height='16'
    viewBox='0 0 16 16'
    width='16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.9174 12.9893L11.0722 10.1441C11.0183 10.0902 10.948 10.0621 10.873 10.0621H10.5636C11.3019 9.20668 11.7495 8.09345 11.7495 6.87476C11.7495 4.18192 9.5676 2 6.87476 2C4.18192 2 2 4.18192 2 6.87476C2 9.5676 4.18192 11.7495 6.87476 11.7495C8.09345 11.7495 9.20668 11.3019 10.0621 10.5636V10.873C10.0621 10.948 10.0926 11.0183 10.1441 11.0722L12.9893 13.9174C13.0995 14.0275 13.2776 14.0275 13.3877 13.9174L13.9174 13.3877C14.0275 13.2776 14.0275 13.0995 13.9174 12.9893ZM6.87476 10.6246C4.80299 10.6246 3.12495 8.94654 3.12495 6.87476C3.12495 4.80299 4.80299 3.12495 6.87476 3.12495C8.94654 3.12495 10.6246 4.80299 10.6246 6.87476C10.6246 8.94654 8.94654 10.6246 6.87476 10.6246Z'
      fill='#092235'
      fillOpacity='0.32'
    />
  </svg>
)

export default function SetImage({
  className = '',
  labelText,
  width,
  height = '124px',
  value,
  viewOnly,
  onChange,
  onRemove,
}: Iprops): JSX.Element {
  const [isVisible, setIsVisible] = useState(false)
  const [curTab, setCurTab] = useState<'stock' | 'own'>('stock')
  const [searchValue, setSearchValue] = useState('')
  const [images, setImages] = useState<string[]>([])
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState(false)
  const [removeShow, setRemoveShow] = useState(false)

  const handleSearchImage = async (keyword: string, page = 1) => {
    setLoading(true)
    try {
      const res = await getUnsplashPhotosApi({
        keyword,
        page,
        per_page: 10,
      })
      const imgs =
        res?.map((v: any) => ({
          small: v?.urls?.small_s3,
          regular: v?.urls?.regular,
        })) || []
      setPage(page)
      if (page === 1) {
        setImages(imgs)
      } else {
        setImages(prev => [...prev, ...imgs])
      }
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  return (
    <>
      <div className={className} style={{ fontSize: 0 }}>
        <Label className='text-xs text-silver'>{labelText}</Label>
        <div
          className='rounded-lg bg-field p-2 relative overflow-hidden'
          style={{ width, height }}
        >
          {value ? (
            <>
              <div className='absolute inset-0 z-10 overflow-hidden flex justify-center items-center'>
                <img src={value} className='h-[107px] rounded-lg' />
              </div>
              {!viewOnly && (
                <div
                  className={classnames(
                    'absolute inset-0 z-20 overflow-hidden flex justify-center items-center flex-col bg-opacity-64 transition duration-300 ease-in-out',
                    removeShow ? 'opacity-1' : 'opacity-0',
                  )}
                  onMouseOver={() => setRemoveShow(true)}
                  onMouseOut={() => setRemoveShow(false)}
                >
                  <div
                    className='mt-4 px-4 py-2 bg-blue text-white text-xs font-bold cursor-pointer rounded-lg'
                    onClick={() => {
                      setIsVisible(true)
                    }}
                  >
                    REPLACE IMAGE
                  </div>
                  <div
                    className='text-white text-xs font-bold pt-4 cursor-pointer'
                    onClick={onRemove}
                  >
                    REMOVE
                  </div>
                </div>
              )}
            </>
          ) : (
            <div
              className='rounded-lg border border-zinc box-border border-dashed h-full flex justify-center items-center	cursor-pointer'
              onClick={() => {
                if (!viewOnly) {
                  setIsVisible(true)
                }
              }}
            >
              <div className='text-center'>
                <SolidImage
                  size={24}
                  className='text-silver flex justify-center'
                />
                <div className='text-sm font-semibold text-silver mt-1'>
                  <span className='text-blue'>Click here </span>
                  to set the image
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalFull
        open={isVisible}
        toggle={() => setIsVisible(!isVisible)}
        title='Set The Campaign Image'
        footer={null}
      >
        <div>
          <div className='flex items-center bg-field h-9 rounded-[64px] p-1 text-xs text-ink font-bold text-center leading-7 cursor-pointer'>
            <div
              onClick={() => setCurTab('stock')}
              className={classnames('w-[244px] h-7 rounded-[64px]', {
                'bg-white': curTab === 'stock',
              })}
            >
              Stock Image
            </div>
            <div
              onClick={() => setCurTab('own')}
              className={classnames('w-[244px] h-7 rounded-[64px]', {
                'bg-white': curTab === 'own',
              })}
            >
              Upload Your Own
            </div>
          </div>
          {curTab === 'own' && (
            <div className='mt-3'>
              <Upload
                labelText='Image'
                onChange={(blob, blobUrl) => {
                  onChange?.(blobUrl, blob)
                  setTimeout(() => {
                    setIsVisible(false)
                  }, 500)
                }}
              ></Upload>
            </div>
          )}
          {curTab === 'stock' && (
            <div className='w-full mt-6'>
              <div className='flex mb-6'>
                <Field
                  containerClassName='flex-1'
                  name='Stock Image'
                  value={searchValue}
                  onChange={e => {
                    setSearchValue(e.target.value)
                  }}
                  placeholder='Search'
                  suffix={SEARCHICON}
                  type='text'
                />
                <Button
                  size='md'
                  loading={loading}
                  color={searchValue?.length ? 'primary' : 'secondary'}
                  className={classnames(
                    'ml-4',
                    searchValue?.length ? 'text-white' : 'text-dark-16',
                  )}
                  onClick={() => handleSearchImage(searchValue)}
                >
                  SEARCH
                </Button>
              </div>
              <ImageList
                images={images}
                loading={loading}
                onChange={item => {
                  onChange(item)
                  setIsVisible(false)
                }}
                onNextPage={() => {
                  handleSearchImage(searchValue, page + 1)
                }}
              />
              <div className='flex items-center justify-center pt-6  border-t border-zinc text-ink text-base'>
                <UnsplashIcon />
                <span className='ml-2'>
                  Free stock images powered by Unsplash.com
                </span>
              </div>
            </div>
          )}
        </div>
      </ModalFull>
    </>
  )
}

interface ImageProps {
  images: string[]
  loading: boolean
  onChange: (image: string) => void
  onNextPage: (page?: number) => void
}

function ImageList({ images, loading, onChange, onNextPage }: ImageProps) {
  if (!images.length) {
    return null
  }
  return (
    <>
      <div className='pb-4'>
        <div className='h-[400px] overflow-scroll scrollbar-hide'>
          <Waterfall columnWidth={236} columnCount={2} gap={24}>
            {images.map((item: any, index) => {
              return (
                <li
                  key={index}
                  className='w-[236px] cursor-pointer'
                  onClick={() => onChange(item.regular)}
                >
                  <div className='w-full min-h-[50px] mb-6 overflow-hidden rounded border border-transparent hover:border-blue'>
                    <img src={item.small} alt='' />
                  </div>
                </li>
              )
            })}
          </Waterfall>
          <div
            className='w-[160px] h-10 leading-10 m-auto cursor-pointer rounded-lg bg-field text-ink text-center font-bold'
            onClick={() => onNextPage()}
          >
            {loading ? 'loading...' : 'LOAD MORE'}
          </div>
        </div>
      </div>
    </>
  )
}

// // 模拟数据
// const defimages = [
//   'https://picsum.photos/640/200/?random',
//   'https://picsum.photos/360/640/?random',
//   'https://picsum.photos/480/720/?random',
//   'https://picsum.photos/480/640/?random',
//   'https://picsum.photos/360/?random',
//   'https://picsum.photos/360/520/?random',
//   'https://picsum.photos/520/360/?random',
//   'https://picsum.photos/520/360/?random',
//   'https://picsum.photos/520/360/?random',
//   'https://picsum.photos/720/640/?random',
// ]

// const handleSearchImage = async (keyword: string, page = 1) => {
//   function random(min: number, max: number) {
//     return min + Math.floor(Math.random() * (max - min + 1))
//   }
//   const arr: any[] = []
//   for (let i = 0; i < 9; i++) {
//     const imgSrc = `${defimages[i]}=${random(1, 10000)}`
//     arr.push(imgSrc)
//   }
//   setImages(prev => [...prev, ...arr])
// }

// import Masonry from '@mui/lab/Masonry'
// "@mui/lab": "^5.0.0-alpha.74",
// "@mui/material": "^5.5.2",
// function ImageList1({ images, loading, onChange, onNextPage }: ImageProps) {
//   return (
//     <>
//       {!!images.length && (
//         <div className='pb-4'>
//           <ul className='h-[400px] overflow-scroll scrollbar-hide'>
//             <Masonry columns={2} defaultHeight={200}>
//               {images.map((item: any, index) => {
//                 return (
//                   <li
//                     key={index}
//                     className='w-[236px] overflow-hidden border-box rounded mb-6 cursor-pointer border border-transparent hover:border-blue'
//                     onClick={() => onChange(item.regular)}
//                   >
//                     <img className='w-[236px]' src={item.small} alt='' />
//                   </li>
//                 )
//               })}
//             </Masonry>
//             <div
//               className='w-[160px] h-[40px] m-auto cursor-pointer rounded-lg bg-field text-ink text-center leading-[40px] font-bold'
//               onClick={() => onNextPage()}
//             >
//               {loading ? 'loading...' : 'LOAD MORE'}
//             </div>
//           </ul>
//         </div>
//       )}
//     </>
//   )
// }

// .MuiMasonry-root {
//   margin: 0 !important;
//   width: 496px !important;
//   overflow: hidden;
//   li {
//     margin: 0 0 24px 0 !important;
//     width: 236px !important;
//     &:nth-child(odd) {
//       margin-right: 24px !important;
//     }
//   }
// }
