import { useFormContext } from '../helpers/context'

export default function PageCenterTitle() {
  const { formData, type } = useFormContext()
  const { status, name } = formData
  if (type === 'one_off') {
    if (status === 'draft' || status === 'scheduled') {
      return <>One-Off Campaign Setting</>
    } else {
      return <>{name}</>
    }
  } else if (type === 'automated') {
    if (status === 'draft' || status === 'scheduled') {
      return <>{name}</>
    } else {
      return (
        <>
          {name} <span className='text-base font-normal'>[Automated]</span>
        </>
      )
    }
  } else {
    return <>{type}</>
  }
}
