import { AngleLeft } from '@rushable/icons'
import Button from 'components/Button'
import { useHistory, useParams } from 'react-router'

import { useFormContext } from '../helpers/context'

export default function PageLeftTitle() {
  const { brandId, locationId } =
    useParams<{ brandId: string; locationId: string }>()
  const history = useHistory()
  const { addOpenData, formData, handleFormDataChange } = useFormContext()
  const type = formData?.type
  const status = addOpenData.item?.status
  const isDraft = status === 'draft' || status === 'scheduled'
  const listUrl =
    type === 'one_off'
      ? `/brand/${brandId}/location/${locationId}/outbound-campaign`
      : `/brand/${brandId}/location/${locationId}/outbound-campaign/automated-campaign`

  return (
    <Button
      color='secondary-link'
      onClick={() => {
        if (addOpenData.item && !isDraft && formData.status !== status) {
          // 返回 Detail Overview Form
          handleFormDataChange('status', status)
          return
        }
        history.push(listUrl)
      }}
    >
      <AngleLeft className='text-silver mr-1' size={16} />
      GO BACK
    </Button>
  )
}
