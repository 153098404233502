import { TBrand } from 'types/brand'

// 再添加需要 pro package 的页面，只需要修改 isProPackagePath 和 isProPackageName
export function isProPackagePath(path: string, brand: TBrand | null) {
  if (path.includes('gift-card') && brand?.gift_card) {
    // brand.gift_card === true 时，gift card 不需要开启 Pro Package 也可以使用
    return false
  }
  if (path.includes('loyalty-rewards') && brand?.loyalty) {
    // brand.loyalty === true 时，loyalty 不需要开启 Pro Package 也可以使用
    return false
  }
  return (
    path.includes('gift-card') ||
    path.includes('loyalty-rewards') ||
    path.includes('recruiting') ||
    path.includes('smart-review')
  )
}

export function isProPackageName(name: string, brand: TBrand | null) {
  if (name === 'Gift Card' && brand?.gift_card) {
    // brand.gift_card === true 时，gift card 不需要开启 Pro Package 也可以使用
    return false
  }
  if (name === 'Loyalty' && brand?.loyalty) {
    // brand.loyalty === true 时，loyalty 不需要开启 Pro Package 也可以使用
    return false
  }
  return (
    name === 'Gift Card' ||
    name === 'Loyalty' ||
    name === 'Recruiting' ||
    name === 'Smart Review'
  )
}
